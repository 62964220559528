import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import { Loader } from "./components/Loader";
const Home = lazy(() => import("./pages/Home"));
const VCard = lazy(() => import("./pages/VCard"));
const Services = lazy(() => import("./pages/Services"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Post = lazy(() => import("./pages/Posts/post"));
const Posts = lazy(() => import("./pages/Posts"));
const Cases = lazy(() => import("./pages/Cases"));
const Events = lazy(() => import("./pages/Events"));
const Event = lazy(() => import("./pages/Events/Event"));
const Vacancies = lazy(() => import("./pages/Vacancies"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Cartinuity = lazy(() => import("./components/landings/Cartinuity"));
const A2B = lazy(() => import("./components/landings/A2B"));
const Invet = lazy(() => import("./components/landings/Invet"));
const Page404 = lazy(() => import("./pages/Page404"));
const Grammarly = lazy(() => import("./components/landings/Grammarly"));
const Shapely = lazy(() => import("./components/landings/Shapely"));
const Nextres = lazy(() => import("./components/landings/Nextres"));
const Pango = lazy(() => import("./components/landings/Pango"));
const Emerge = lazy(() => import("./components/landings/Emerge"));
const WeedAR = lazy(() => import("./components/landings/WeedAR"));
const Vacancy = lazy(() => import("./components/Vacancy"));
const Restaurants = lazy(() => import("./pages/Restaurants"));
const AndroidNativeAutomation = lazy(() =>
  import("./pages/Service/AndroidNativeAutomation")
);
const IosDevelopment = lazy(() => import("./pages/Service/IosDevelopment"));
const AndroidDevelopment = lazy(() =>
  import("./pages/Service/AndroidDevelopment")
);
const ArVr = lazy(() => import("./pages/Service/ArVr"));
const Accessibility = lazy(() => import("./pages/Service/Accessibility"));
const WebDevelopment = lazy(() => import("./pages/Service/WebDevelopment"));
const IosNativeAutomation = lazy(() =>
  import("./pages/Service/IosNativeAutomation")
);
const CICD = lazy(() => import("./pages/Service/CICD"));
const AI = lazy(() => import("./pages/Service/AI"));

const Router = () => {
  const renderLoader = () => <Loader />;

  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/events" element={<Events />} />
        <Route path="/restaurants" element={<Restaurants />} />
        <Route path="/events/:slug" element={<Event />} />
        <Route
          path="/services/android-native-automation"
          element={<AndroidNativeAutomation />}
        />
        <Route
          path="/services/android-development"
          element={<AndroidDevelopment />}
        />
        <Route path="/services/ar-vr" element={<ArVr />} />
        <Route path="/services/accessibility" element={<Accessibility />} />
        <Route path="/services/ios-development" element={<IosDevelopment />} />
        <Route path="/services/web-developments" element={<WebDevelopment />} />
        <Route path="/services/ai" element={<AI />} />
        <Route
          path="/services/ios-native-automation"
          element={<IosNativeAutomation />}
        />
        <Route path="/services/cicd" element={<CICD />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/:slug" element={<Post />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about-us/:slug" element={<VCard />} />
        <Route path="/cases" element={<Cases />} />
        <Route path="/cases/cartinuity" element={<Cartinuity />} />
        <Route path="/cases/grammarly" element={<Grammarly />} />
        <Route path="/cases/nextres" element={<Nextres />} />
        <Route path="/cases/weedar" element={<WeedAR />} />
        <Route path="/cases/pango" element={<Pango />} />
        <Route path="/cases/emerge" element={<Emerge />} />
        <Route path="/cases/a2b" element={<A2B />} />
        <Route path="/cases/invet" element={<Invet />} />
        <Route path="/vacancies" element={<Vacancies />} />
        <Route path="/vacancies/:slug" element={<Vacancy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cases/shapely" element={<Shapely />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
