import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import classes from "../../scss/Footer.module.scss";
import Container from "../commons/Container";
import ListTitle from "../commons/ListTitle";
// import Sci from "../Sci";
// import { sci } from "../../data/sci";
import { footer } from "../../data/footer";
import ListWrapper from "../commons/ListWrapper";
import LinksList from "../commons/LinksList";
import ContactsList from "../commons/ContactsList";
// import Mailchimp from "../Mailchimp";
import { ReactComponent as FooterLogo } from "./footer_logo.svg";

export default function Footer({ menu, offices }) {
  const year = new Date().getFullYear();

  const { company, services } = menu;

  return (
    <footer className={classes.footer}>
      <Container>
        <section className={classes.footer__body}>
          <div className={classes.footer__top}>
            <div className={classes.footer__logo}>
              <Link
                to={{
                  pathname: "/",
                }}
              >
                <FooterLogo />
              </Link>
            </div>
          </div>
          <div className={`${classes.footer__center}`}>
            <ListWrapper>
              <ListTitle title={company.name} />
              <LinksList list={company.items?.filter(c => c.id !== 509)} />
            </ListWrapper>
            <ListWrapper>
              <ListTitle title={services.name} />
              <LinksList list={services.items} />
            </ListWrapper>
            <ListWrapper className={classes.footer__contacts}>
              <ListTitle title="Contacts" />
              <ContactsList list={offices} support={footer.support} />
            </ListWrapper>
          </div>
          <div
            className={classes.footer__bottom}
            style={{
              justifyContent: "center",
            }}
          >
            <div className={classes.footer__links}>
              {footer.links.map(({ href, text }) => (
                <Link key={uuid()} to={href}>
                  {text}
                </Link>
              ))}
            </div>
            {/* <Sci data={sci} className={classes.footer__sci} />
            <Mailchimp className={classes.footer__widget} /> */}
          </div>
          <div className={classes.footer__copyright}>
            {year} Agilistry Inc. (c) All rights reserved
          </div>
        </section>
      </Container>
    </footer>
  );
}
