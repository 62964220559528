export const team = {
  team: {
    data: [],
    loading: true,
    hasMore: true,
  },

  queries: {
    team: {
      _fields: "custom_fields, title, slug, featured_media,img_url",
      per_page: 8,
      page: 1,
    },
  },
};
