import { HelmetComponent } from "../Helmet";
import Header from "../Header";
import Footer from "../Footer";
import { useSeo } from "../../customHooks/useSeo";
import { menuIds } from "../../constants/api";
import { findItemById } from "../../helpers/findItemById";
import classes from "./index.module.scss";
import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/global/globalContext";
import { useLocation } from "react-router-dom";
import { PopupContext } from "../../context/PopupContext";
import { useScrollToTop } from "../../customHooks/useScrollToTop";
import { PopupModal } from "react-calendly";
import smoothscroll from "smoothscroll-polyfill";
import headerLogo from "../../assets/img/header_logo.svg";
import logoMonosoft from "../../assets/img/logoMonosoft.svg";

export default function Layout({ children }) {
  const { getMenu, menu, offices, getOffices } = useContext(GlobalContext);
  const { isPopupOpened, closePopup } = useContext(PopupContext);
  const location = useLocation();

  const seo = useSeo();

  useScrollToTop();

  useEffect(() => {
    const funnel = new URLSearchParams(location.search).get("funnel");
    funnel && localStorage.setItem("query", JSON.stringify(funnel));
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();

    !menu && getMenu();
    !offices && getOffices();
  }, []);

  return (
    <>
        {/*<div className={classes.headerNotice}>*/}
        {/*    <span className={classes.headerNoticeLeftPart}>*/}
        {/*        <img src={headerLogo} alt={"logo"} className={classes.headerLogo}/>*/}
        {/*        is now a part of*/}
        {/*        <img src={logoMonosoft} alt={"Monosoft"} className={classes.monosoftLogo}/>*/}
        {/*        .*/}
        {/*    </span>*/}

        {/*    <a href={"https://monosoft.dev/"} target="_blank" rel="noreferrer noopener" className={classes.linkToMonosoft}>*/}
        {/*        Check out our new website!*/}
        {/*    </a>*/}
        {/*</div>*/}
      {seo && <HelmetComponent seo={seo.yoast_head} />}
      <Header
        menu={{
          services: findItemById(menu, menuIds.HEADER_SERVICES).items,
          resources: findItemById(menu, menuIds.HEADER_RESOURCES).items,
          company: findItemById(menu, menuIds.HEADER_COMPANY).items?.filter(
            (item) => item.id !== 1366
          ),
        }}
      />
      <main className={classes.main}>{children}</main>
      <Footer
        menu={{
          services: findItemById(menu, menuIds.SERVICES),
          company: findItemById(menu, menuIds.ABOUT_THE_COMPANY),
          solutions: findItemById(menu, menuIds.SOLUTIONS),
        }}
        offices={offices}
      />
      {location.pathname !== "/" && (
        <PopupModal
          url="https://calendly.com/agilistry/demo"
          open={isPopupOpened?.calendly}
          rootElement={document.getElementById("engenious")}
          onModalClose={() => closePopup("calendly")}
        />
      )}
    </>
  );
}
