import classes from "./index.module.scss";
import { renderMenu } from "./renderMenu";

export default function Navigation({ menu }) {
  const isMenu = (menu) => !!Object.values(menu).filter(Boolean).length;

  if (!isMenu(menu)) {
    return null;
  }
  return <nav className={classes.nav}>{renderMenu(menu)}</nav>;
}
