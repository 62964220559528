import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import GlobalState from "./context/global/globalState";
import FormState from "./context/form/formState";
import LandingsAnimationContextProvider from "./context/LandingsAnimationContext";
import PopupContextProvider from "./context/PopupContext";
import FetchContextProvider from "./context/FetchContext";

const tagManagerArgs = {
  gtmId: "GTM-PGDM6NN",
};

TagManager.initialize(tagManagerArgs);

const RootApp = () => (
  <BrowserRouter>
    <LandingsAnimationContextProvider>
      <GlobalState>
        <FormState>
          <PopupContextProvider>
            <FetchContextProvider>
              <App />
            </FetchContextProvider>
          </PopupContextProvider>
        </FormState>
      </GlobalState>
    </LandingsAnimationContextProvider>
  </BrowserRouter>
);

const container = document.getElementById("engenious");
const root = createRoot(container);
root.render(<RootApp />);
