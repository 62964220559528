import { useEffect } from "react";

export const useClickOutside = (ref, callback, exception) => {
  const handleClick = (e) => {
    const target = document.querySelector(exception);

    if (target && target.contains(e.target)) {
      return;
    }
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
