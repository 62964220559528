import logo from "../assets/img/footer/footer-logo.png";
import mail from "../assets/img/footer/support.svg";

export const footer = {
  logo,
  // btn: {
  //   link: "/subscribe",
  //   text: "subscribe",
  //   isExternal: false,
  // },
  links: [
    {
      href: "/privacy-policy",
      text: "Privacy & Terms",
      isExternal: false,
    },
  ],
  support: {
    href: `info@agilistrysoft.com`,
    icon: mail,
  },
};
