import React from "react";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

export function HelmetComponent({ seo }) {
  const parsedYoastHead = seo && parse(seo);

  const schema = parsedYoastHead?.find(
    (element) => element?.props?.type === "application/ld+json"
  );

  return (
    <>
      {parsedYoastHead && (
        <Helmet>
          {parsedYoastHead}
          <script type="application/ld+json">
            {schema?.props?.dangerouslySetInnerHTML?.__html}
          </script>
        </Helmet>
      )}
    </>
  );
}
