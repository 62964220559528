import { Link } from "react-router-dom";
import "./index.css";
import { ReactComponent as HeaderLogo } from "./header_logo.svg";

export const Logo = () => {
  return (
    <Link
      to={{
        pathname: "/",
      }}
      className={`logo`}
    >
      <HeaderLogo />
    </Link>
  );
};
