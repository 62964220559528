export const posts = {
  posts: {
    data: [],
    loading: true,
  },
  post: { data: [], loading: true },

  slugs: {
    data: [],
    loading: true,
  },
  otherPosts: {
    data: [],
    loading: true,
  },
  tags: {
    data: [],
    loading: true,
  },
  filters: {
    activeTags: [],
  },
  queries: {
    posts: {
      _embed: "wp:term",
      _fields:
        "_links, custom_fields, title, content, slug, featured_media,img_url, id, date",
      per_page: 6,
      page: 1,
    },
    tags: {
      _embed: "wp:term",
      _fields: "_links",
      per_page: 100,
    },
  },
};
