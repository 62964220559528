import Router from "./router";
import "./common.css";
import "./animations.css";
import "./critical.css";
import "./scss/index.scss";
import Layout from "./components/Layout/Layout";

const App = () => {
  return (
    <Layout>
      <Router />
    </Layout>
  );
};

export default App;
