import React, { createContext, useState } from "react";

export const LandingsAnimationContext = createContext();

const LandingsAnimationContextProvider = (props) => {
  const [isGrammarlyAnimationFinished, setIsGrammarlyAnimationFinished] =
    useState(false);
  const [isGrammarlyAnimationStarted, setIsGrammarlyAnimationStarted] =
    useState(false);
  const [isPangoAnimationFinished, setIsPangoAnimationFinished] =
    useState(false);
  const [isPangoAnimationStarted, setIsPangoAnimationStarted] = useState(false);
  const [isEmergeAnimationFinished, setIsEmergeAnimationFinished] =
    useState(false);
  const [isEmergeAnimationStarted, setIsEmergeAnimationStarted] =
    useState(false);
  const [isCartinuityAnimationFinished, setIsCartinuityAnimationFinished] =
    useState(false);
  const [isCartinuityAnimationStarted, setIsCartinuityAnimationStarted] =
    useState(false);
  const [isA2BAnimationFinished, setIsA2BAnimationFinished] = useState(false);
  const [isA2BAnimationStarted, setIsA2BAnimationStarted] = useState(false);
  const [isInvetAnimationFinished, setIsInvetAnimationFinished] =
    useState(false);
  const [isInvetAnimationStarted, setIsInvetAnimationStarted] = useState(false);

  return (
    <LandingsAnimationContext.Provider
      value={{
        isGrammarlyAnimationFinished,
        setIsGrammarlyAnimationFinished,
        isGrammarlyAnimationStarted,
        setIsGrammarlyAnimationStarted,
        isPangoAnimationFinished,
        setIsPangoAnimationFinished,
        isPangoAnimationStarted,
        setIsPangoAnimationStarted,
        isEmergeAnimationFinished,
        setIsEmergeAnimationFinished,
        isEmergeAnimationStarted,
        setIsEmergeAnimationStarted,
        isCartinuityAnimationFinished,
        setIsCartinuityAnimationFinished,
        isCartinuityAnimationStarted,
        setIsCartinuityAnimationStarted,
        isA2BAnimationFinished,
        setIsA2BAnimationFinished,
        isA2BAnimationStarted,
        setIsA2BAnimationStarted,
        isInvetAnimationFinished,
        setIsInvetAnimationFinished,
        isInvetAnimationStarted,
        setIsInvetAnimationStarted,
      }}
    >
      {props.children}
    </LandingsAnimationContext.Provider>
  );
};

export default LandingsAnimationContextProvider;
