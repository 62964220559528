import classes from "./index.module.scss";
import { NavLink } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { ReactComponent as Burger } from "./images/burger.svg";
import { ReactComponent as Robot } from "./images/robot.svg";
import { GlobalContext } from "../../context/global/globalContext";
import { useContext, useEffect, useRef, useState } from "react";
import { findItemById } from "../../helpers/findItemById";
import { menuIds } from "../../constants/api";
import gsap from "gsap";

import { useClickOutside } from "../../customHooks/useClickOutside";
import useMediaQuery from "../../customHooks/useMediaQuery";

gsap.config({
  force3D: false,
  nullTargetWarn: false,
  z: 0.1,
  rotationZ: 0.01,
  transformPerspective: 1000,
});

export default function Menu() {
  const { menu } = useContext(GlobalContext);
  const [navigation, setNavigation] = useState(null);
  const [open, setOpen] = useState(false);
  const wrapper = useRef();
  const isMobile = useMediaQuery("(max-width:56.25em)");

  const handleOpen = () => {
    setOpen(true);

    const tl = gsap.timeline();

    let timeout = setTimeout(() => {
      tl.to(".burger-circle", { scale: -1, transformOrigin: "center" })
        .to(".burger-line", { fill: "#fff" }, 0)
        .to(".burger-line-1", { rotate: 45, transformOrigin: "6px 4px" }, 0)
        .to(".burger-line-2", { rotate: -45, transformOrigin: "7px -2px" }, 0)
        .to(
          `.${classes.backdrop}`,
          {
            autoAlpha: 1,
            duration: 0.5,
          },
          0
        )
        .to(
          `.${classes.wrapper}`,
          {
            x: 0,
            duration: 1,
            ease: "elastic.out(1, 0.5)",
            onComplete: () => clearTimeout(timeout),
          },
          "<0.1"
        )
        .fromTo(
          ".burger-portal",
          {
            scale: 0.7,
            transformOrigin: "50% 50%",
          },
          {
            scale: 1,
            repeat: -1,
            transformOrigin: "50% 50%",
            yoyo: true,
            duration: 1,
          }
        )
        .to(
          ".burger-robot",
          {
            y: "10px",
            repeat: -1,
            transformOrigin: "50% 50%",
            yoyo: true,
            duration: 1,
          },
          "<"
        );
      return tl;
    }, 0);
  };
  const handleClose = () => {
    const tl = gsap.timeline();

    tl.to(".burger-circle", { scale: 1, transformOrigin: "center" })
      .to(".burger-line", { fill: "#EE7208" }, 0)
      .to(".burger-line-1", { rotate: 0, transformOrigin: "6px 4px" }, 0)
      .to(".burger-line-2", { rotate: 0, transformOrigin: "7px -2px" }, 0)
      .to(
        `.${classes.backdrop}`,
        {
          autoAlpha: 0,
          duration: 0.5,
        },
        0
      )
      .to(
        `.${classes.wrapper}`,
        {
          x: "100%",
          duration: 0.5,
          onComplete: () => {
            setOpen(false);
          },
        },
        "<0.1"
      );
    return tl;
  };

  useClickOutside(wrapper, handleClose, ".webinar-popup");

  useEffect(() => {
    !navigation &&
      menu &&
      setNavigation(findItemById(menu, menuIds.MAIN_MENU).items);
  }, [menu, navigation]);

  useEffect(() => {
    open
      ? document.body.classList.add(classes.hidden)
      : document.body.classList.remove(classes.hidden);

    if (!open) {
      gsap.set(".burger-circle", { scale: 1 });
      gsap.set(".burger-line", { fill: "#EE7208" });
      gsap.set(".burger-line-1", { rotate: 0 });
      gsap.set(".burger-line-2", { rotate: 0 });
    }

    return () => {
      document.body.classList.remove(classes.hidden);
    };
  }, [open]);

  useEffect(() => {
    !isMobile && setOpen(false);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation();
          return !open ? handleOpen() : handleClose();
        }}
        className={classes.burger}
      >
        <Burger />
      </button>
      {open ? (
        <>
          <div className={classes.backdrop}></div>
          <div ref={wrapper} className={classes.wrapper}>
            <nav className={classes.menu}>
              {navigation &&
                navigation.map(({ url, title }) => (
                  <NavLink
                    onClick={() => setOpen(false)}
                    key={uuid()}
                    to={url}
                    className={(navData) =>
                      navData.isActive
                        ? `${classes.link} ${classes.link_active}`
                        : classes.link
                    }
                  >
                    {title}
                  </NavLink>
                ))}
              <Robot className={classes.robot} />
            </nav>
          </div>
        </>
      ) : null}
    </>
  );
}
