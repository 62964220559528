export const events = {
  upcoming: {
    data: [],
    loading: false,
  },
  onDemand: {
    data: [],
    loading: false,
  },
  event: { data: [], loading: false },

  tags: {
    data: [],
    loading: false,
  },
  filters: {
    activeTags: [],
  },
  queries: {
    upcoming: {
      _embed: "wp:term",
      _fields: "_links, custom_fields, title, content, slug",
      webinar_status: 104,
      per_page: 100,
    },
    onDemand: {
      _embed: "wp:term",
      _fields: "_links, custom_fields, title, content, slug",
      webinar_status: 103,
      per_page: 2,
      page: 1,
    },
    tags: {
      _embed: "wp:term",
      _fields: "_links",
      per_page: 100,
    },
  },
};
