export const apiURL = "https://api.agilistrysoft.com/wp-json/wp/v2";

export const contactFormURL =
  "https://api.agilistrysoft.com/wp-json/contact-form-7/v1/contact-forms";

export const youtubeSubscribeUrl =
  "https://engenious.us12.list-manage.com/subscribe/post-json?u=45d28f304740b7e895a0adcae&amp;id=b0bf909d89";

export const stagingUrl =
  "https://gmail.us4.list-manage.com/subscribe/post-json?u=9f15a7ecba50b523127632e6c&amp;id=e7532deaee";

export const menuUrl =
  "https://api.agilistrysoft.com/wp-json/wp-api-menus/v2/menus";

export const menuIds = {
  ABOUT_THE_COMPANY: 49,
  SERVICES: 51,
  SOLUTIONS: 52,
  MAIN_MENU: 53,
  HEADER_MENU: 54,
  HEADER_RESOURCES: 107,
  HEADER_SERVICES: 106,
  HEADER_COMPANY: 108,
};

export const pagesIds = {
  home: 34,
  contactUs: 15,
  termsOfUse: 40,
  privacyPolicy: 38,
  solutions: 140,
  policy: 404,
};

export const contactFormsIds = {
  contactUs: 46,
  sendCV: 254,
};
