import React, { useState, useMemo } from "react";
import { GlobalContext } from "./globalContext";
import { apiURL } from "../../constants/api";
import { menuUrl, menuIds } from "../../constants/api";

const GlobalState = ({ children }) => {
  const [pages, setPages] = useState([]);
  const [services, setServices] = useState(null);
  const [vacancies, setVacancies] = useState([]);
  const [tags, setTags] = useState(null);
  const [categories, setCategories] = useState(null);
  const [tagsIDActive, setTegsIDActive] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [menu, setMenu] = useState(null);
  const [team, setTeam] = useState(null);
  const [offices, setOffices] = useState(null);
  const [vacancyPage, setVacancyPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [totalVacancies, setTotalVacancies] = useState(0);
  const [currentVacancy, setCurrentVacancy] = useState(null);

  const getMenu = () => {
    const ids = Object.values(menuIds);
    const promises = ids.map((id) => fetch(`${menuUrl}/${id}`));

    Promise.all(promises).then((res) =>
      Promise.all(res.map((res) => res.json())).then((data) => setMenu(data))
    );
  };

  const getOffices = () => {
    fetch(
      `${apiURL}/offices?_fields=id,custom_fields,title,featured_media,img_url`
    )
      .then((data) => data.json())
      .then((offices) => setOffices(offices.filter(i => ![1684, 520].includes(i.id)).reverse()));
      // .then((offices) => setOffices(offices.reverse()));
  };

  const getPage = (id) => {
    const pageExists = getCurrentPage(id);
    if (pageExists) return;
    fetch(`${apiURL}/pages/${id}?_fields=id,custom_fields,content,title`)
      .then((data) => data.json())
      .then((page) => setPages([...pages, page]));
  };

  const getServices = () => {
    fetch(
      `${apiURL}/services?_fields=id,custom_fields,content,title,featured_media,img_url,slug`
    )
      .then((data) => data.json())
      .then((services) =>
        [...services].sort(
          (a, b) => a.custom_fields.order - b.custom_fields.order
        )
      )
      .then((services) => setServices(services));
  };

  const getTeam = () => {
    fetch(`${apiURL}/team?title,custom_fields,img_url&page=1&per_page=100`)
      .then((data) => data.json())
      .then((team) => setTeam(team));
  };

  const getCurrentService = (slug) =>
    services?.find((service) => service.slug === slug);

  const getCurrentPage = (id) => pages?.find((page) => page.id === Number(id));

  const getCurrentVacancy = async (slug) => {
    const vacancy = vacancies?.find((vacancy) => vacancy.slug === slug);
    if (vacancy) return setCurrentVacancy(vacancy);
    let result = await (await fetch(`${apiURL}/vacancies?slug=${slug}`)).json();
    setCurrentVacancy(result[0]);
    if (!categories) {
      await getCategories();
    }
  };

  const getTags = async () => {
    let result = await fetch(`${apiURL}/vacancies?per_page=100&_fields=tags`);
    result = await result.json();
    const tagsIds = result
      .reduce((acc, item) => [...acc, ...item.tags], [])
      .toString();
    let tags = await fetch(
      `${apiURL}/tags?include=${tagsIds}&_fields=id,name&per_page=100`
    );

    tags = await tags.json();
    tags.length && setTags(tags);
  };

  const getCategories = async () => {
    let result = await fetch(
      `${apiURL}/vacancies?per_page=100&_fields=categories`
    );
    result = await result.json();
    const categoriesIds = result
      .reduce((acc, item) => [...acc, ...item.categories], [])
      .toString();
    let categories = await fetch(
      `${apiURL}/categories?include=${categoriesIds}&_fields=id,name&per_page=100`
    );

    categories = await categories.json();
    categories.length && setCategories(categories);
  };

  const getVacancies = async (page) => {
    const perPage = 6;
    try {
      setIsLoading(true);

      const tagsQuery = tagsIDActive.length
        ? `&tags=${tagsIDActive.toString()}`
        : "";

      if (!tags) {
        getTags();
      }
      if (!categories) {
        getCategories();
      }

      let result = await fetch(
        `${apiURL}/vacancies?page=${page}&per_page=${perPage}&_fields=id,title,slug,content,custom_fields,tags,categories&${tagsQuery}`
      );

      const vacancies = await result.json();
      setTotalVacancies(result.headers.get("x-wp-total"));
      setHasMore(result.headers.get("x-wp-total") / (page * perPage) > 1);

      if (isFilter) {
        setVacancies([]);
        setVacancies(vacancies);
      } else {
        setVacancies((prev) => [...prev, ...vacancies]);
      }

      setIsFilter(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const value = useMemo(
    () => ({
      getCurrentPage,
      getPage,
      getCurrentService,
      pages,
      services,
      getServices,
      vacancies,
      getVacancies,
      tags,
      tagsIDActive,
      setTegsIDActive,
      isLoading,
      getCurrentVacancy,
      getMenu,
      menu,
      getTeam,
      team,
      offices,
      getOffices,
      vacancyPage,
      setVacancyPage,
      setVacancies,
      hasMore,
      setIsFilter,
      isFilter,
      setHasMore,
      totalVacancies,
      setTotalVacancies,
      categories,
      currentVacancy,
    }),
    [
      pages,
      services,
      vacancies,
      tags,
      isLoading,
      tagsIDActive,
      menu,
      team,
      offices,
      vacancyPage,
      hasMore,
      isFilter,
      totalVacancies,
      categories,
      currentVacancy,
    ]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export default GlobalState;
