import { createContext, useCallback, useMemo, useState } from "react";
import { apiURL } from "../constants/api";
import { events } from "./default-states/events";
import { posts } from "./default-states/posts";
import { team } from "./default-states/team";

export const FetchContext = createContext();

export default function FetchContextProvider({ children }) {
  const [store, setStore] = useState({
    events,
    posts,
    team,
  });

  const getItem = useCallback(
    async ({
      entity,
      id = "",
      query = null,
      propName = "",
      cb = undefined,
    }) => {
      const setLoading = (boolean) =>
        setStore((prev) => {
          const prevState = prev[entity][propName];

          return {
            ...prev,
            [entity]: {
              ...prev[entity],
              [propName]: { ...prevState, loading: boolean },
            },
          };
        });
      try {
        setLoading(true);

        const res = await fetch(
          `${apiURL}/${entity}${id ? "/" + id : ""}${
            query ? "?" + new URLSearchParams(query).toString() : ""
          }`
        );

        let data = await res.json();
        const { page } = query;
        cb && (data = cb(data));

        setStore((prev) => {
          const prevState = prev[entity][propName];
          const prevCurrentPage = prevState?.currentPage;
          const prevData = prevState?.data;
          data = { data };

          if (page) {
            data.total = Number(res.headers.get("x-wp-total"));
            data.totalPages = Number(res.headers.get("x-wp-totalpages"));
            data.currentPage = page;
            data.hasMore = page < data.totalPages;
          }

          if (page && prevCurrentPage && page !== prevCurrentPage) {
            data.data = [...prevData, ...data?.data];
          }

          return {
            ...prev,
            [entity]: {
              ...prev[entity],
              [propName]: { ...prevState, ...data },
            },
          };
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const clearPropNameData = (entity, propName) => {
    setStore((prev) => {
      const prevState = prev[entity][propName];
      return {
        ...prev,
        [entity]: {
          ...prev[entity],
          [propName]: { ...prevState, loading: true, data: [] },
        },
      };
    });
  };

  const toggleActiveTag = (tag, entity) => {
    const res = store[entity].filters.activeTags.includes(tag.id);
    setStore((prev) => {
      const filters = prev[entity].filters;
      const activeTags = filters.activeTags;
      return {
        ...prev,
        [entity]: {
          ...prev[entity],
          filters: {
            ...filters,
            activeTags: res
              ? activeTags.filter((item) => item !== tag.id)
              : [...activeTags, tag.id],
          },
        },
      };
    });
  };

  const checkIsTagActive = (tag, entity) => {
    const activeTags = store[entity].filters.activeTags;
    return activeTags.length ? activeTags.includes(tag.id) : false;
  };

  const resetTags = (entity) => {
    const activeTags = store[entity].filters.activeTags;
    activeTags.length &&
      setStore((prev) => {
        return {
          ...prev,
          [entity]: {
            ...prev[entity],
            filters: {
              ...prev[entity].filters,
              activeTags: [],
            },
          },
        };
      });
  };

  const setFilterQuery = useCallback(
    (activeTags, entity, queryName) =>
      setStore((prev) => {
        const queries = prev[entity].queries;
        const query = queries[queryName];
        const { tags, ...rest } = query;
        let currentQuery = activeTags.length
          ? {
              ...query,
              tags: activeTags.toString(),
            }
          : { ...rest };
        query.page && (currentQuery = { ...currentQuery, page: 1 });
        return {
          ...prev,
          [entity]: {
            ...prev[entity],
            [queryName]: { ...prev[entity][queryName], currentPage: 1 },
            queries: {
              ...queries,
              [queryName]: currentQuery,
            },
          },
        };
      }),
    []
  );

  const setPageQuery = useCallback(
    (entity, queryName, page, rest = {}) =>
      setStore((prev) => {
        const queries = prev[entity].queries;
        const query = queries[queryName];
        return {
          ...prev,
          [entity]: {
            ...prev[entity],
            queries: {
              ...queries,
              [queryName]: {
                ...query,
                page,
                ...rest,
              },
            },
          },
        };
      }),
    []
  );

  const value = useMemo(
    () => ({
      store,
      getItem,
      toggleActiveTag,
      checkIsTagActive,
      resetTags,
      setFilterQuery,
      setPageQuery,
      clearPropNameData,
    }),
    [store]
  );

  return (
    <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
  );
}
