import classes from "./index.module.scss";
import { v4 as uuid } from "uuid";
import Dropdown from "./Dropdown";

export const renderMenu = (menu) => {
  const list = [];
  for (const key in menu) {
    if (menu.hasOwnProperty(key)) {
      menu[key] &&
        list.push(
          <li key={uuid()} className={classes.list__item}>
            <Dropdown list={menu[key]} title={key} />
          </li>
        );
    }
  }

  return <ul className={classes.list}>{list}</ul>;
};
