import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiURL } from "../constants/api";

export const useSeo = () => {
  const { pathname } = useLocation();
  const [seo, setSeo] = useState(null);

  useEffect(() => {
    async function fetchSeo() {
      const splitPath = pathname.split("/");
      let request = "";
      const lastItemFromRoute = splitPath[splitPath.length - 1];

      if (splitPath[splitPath.length - 1]) {
        if (splitPath.length >= 3) {
          request = `${
            splitPath[splitPath.length - 2]
          }?slug=${lastItemFromRoute}`;
        } else {
          request = `pages?search=${lastItemFromRoute.split("-")[0]}`;
        }

        const res = await fetch(
          `${apiURL}/${request}&_fields=title,yoast_head`
        );
        const seoJson = await res.json();
        const findSeo =
          seoJson.length &&
          seoJson.find(({ title }) =>
            title.rendered.toLowerCase().includes("contact")
          );
        setSeo(findSeo || seoJson[0]);
      }
    }

    fetchSeo();
  }, [pathname]);

  return seo;
};
