import { createContext, useMemo, useState } from "react";
export const PopupContext = createContext();

export default function PopupContextProvider({ children }) {
  const [isPopupOpened, setIsPopupOpened] = useState(null);
  const [popupContent, setPopupContent] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const openPopup = (name, content) => {
    setPopupContent(content);
    setIsPopupOpened({
      ...isPopupOpened,
      [name]: true,
    });
  };
  const closePopup = (name) =>
    setIsPopupOpened({ ...isPopupOpened, [name]: false });

  const closePopupHandler = (name) => {
    setIsClosing(true);
    const timeout = setTimeout(() => {
      closePopup(name);
      setIsClosing(false);
      clearTimeout(timeout);
    }, 300);
  };

  const value = useMemo(
    () => ({
      popupContent,
      isPopupOpened,
      isClosing,
      openPopup,
      closePopup,
      closePopupHandler,
    }),
    [isPopupOpened, isClosing, popupContent]
  );

  return (
    <PopupContext.Provider value={value}>{children}</PopupContext.Provider>
  );
}
