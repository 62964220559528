import React from "react";
import "./index.css";
export const Loader = () => {
  return (
    <div className="preloader__wrapper">
      <div className="preloader">
        <div className="preloader__line">
          <div className="block1 anlBlock">
            <svg
              className="an anl"
              width="116"
              height="116"
              viewBox="0 0 72 72"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48.7494 14.5714H23.2495C20.8884 14.5714 18.9995 12.6667 18.9995 10.2857C18.9995 7.90476 20.8884 6 23.2495 6H48.7494C51.1106 6 52.9994 7.90476 52.9994 10.2857C52.9994 12.6667 51.1106 14.5714 48.7494 14.5714Z"
                fill="#0B132B"
              />
              <path
                d="M48.7494 66.0001H23.2495C20.8884 66.0001 18.9995 64.0954 18.9995 61.7144C18.9995 59.3335 20.8884 57.4287 23.2495 57.4287H48.7494C51.1106 57.4287 52.9994 59.3335 52.9994 61.7144C52.9994 64.0954 51.1106 66.0001 48.7494 66.0001Z"
                fill="#0B132B"
              />
              <path
                d="M18.9999 40.2858H6.24998C3.88888 40.2858 2 38.381 2 36.0001C2 33.6191 3.88888 31.7144 6.24998 31.7144H18.9999C21.361 31.7144 23.2499 33.6191 23.2499 36.0001C23.2499 38.381 21.361 40.2858 18.9999 40.2858Z"
                fill="#0B132B"
              />
              <path
                d="M65.75 40.2858H53C50.6389 40.2858 48.75 38.381 48.75 36.0001C48.75 33.6191 50.6389 31.7144 53 31.7144H65.75C68.1112 31.7144 70 33.6191 70 36.0001C70 38.381 68.1112 40.2858 65.75 40.2858Z"
                fill="#0B132B"
              />
              <path
                d="M40.2495 40.2858H31.7495C29.3884 40.2858 27.4995 38.381 27.4995 36.0001C27.4995 33.6191 29.3884 31.7144 31.7495 31.7144H40.2495C42.6106 31.7144 44.4995 33.6191 44.4995 36.0001C44.4995 38.381 42.6106 40.2858 40.2495 40.2858Z"
                fill="#3454D1"
              />
            </svg>
          </div>
          <div className="textPre">Agilistry</div>
          <div className="block2 anRBlock">
            <svg
              className="an anR"
              width="116"
              height="116"
              viewBox="0 0 72 72"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6667 27.5H14.3333C11.9259 27.5 10 25.6111 10 23.25C10 20.8889 11.9259 19 14.3333 19H18.6667C21.0741 19 23 20.8889 23 23.25C23 25.6111 21.0741 27.5 18.6667 27.5Z"
                fill="#0B132B"
              />
              <path
                d="M57.667 27.5H31.6668C29.2594 27.5 27.3335 25.6111 27.3335 23.25C27.3335 20.8889 29.2594 19 31.6668 19H57.667C60.0741 19 62.0004 20.8889 62.0004 23.25C62.0004 25.6111 60.0741 27.5 57.667 27.5Z"
                fill="#3454D1"
              />
              <path
                d="M57.667 53H31.6668C29.2594 53 27.3335 51.1111 27.3335 48.75C27.3335 46.3889 29.2594 44.5 31.6668 44.5H57.667C60.0741 44.5 62.0004 46.3889 62.0004 48.75C62.0004 51.1111 60.0741 53 57.667 53Z"
                fill="#3454D1"
              />
              <path
                d="M18.6667 53H14.3333C11.9259 53 10 51.1111 10 48.75C10 46.3889 11.9259 44.5 14.3333 44.5H18.6667C21.0741 44.5 23 46.3889 23 48.75C23 51.1111 21.0741 53 18.6667 53Z"
                fill="#0B132B"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
