import classes from "../../../scss/LinksList.module.scss";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import { useDeferredValue } from "react";

export default function LinksList({ list }) {
  let deferredList = useDeferredValue(list);
  return (
    <ul className={classes.list}>
      {deferredList?.map(({ url, title, description }) => (
        <li key={uuid()} className={classes.list__item}>
          {description ? (
            <a href={url} target="_blank" rel="noreferrer">
              {title}
            </a>
          ) : (
            <Link to={url}>{title}</Link>
          )}
        </li>
      ))}
    </ul>
  );
}
