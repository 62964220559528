import React, { useState } from "react";
import { contactFormURL } from "../../constants/api";
import jsonp from "jsonp";
import { FormContext } from "./formContext";
import axios from "axios";
import moment from "moment";
const FormState = ({ children }) => {
  const [notif, setNotification] = useState({});
  const [notifCV, setNotifCV] = useState(null);
  const [pdfCV, setPdfCV] = useState(null);
  const [isLoadigCV, setIsloadingCV] = useState(false);

  const postDataToSheets = async (data) => {
    try {
      await fetch(
        "https://sheet.best/api/sheets/e708f760-301c-448d-98ca-8e46db934c51",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getIP = async (email) => {
    const { data } = await axios.get("https://ipapi.co/json/");
    if (data) {
      window.fbq("track", "Lead");
      window.fbq("track", "SubmitApplication");
      const values = {
        date: moment().utc().format("MM-DD-YYYY, HH:mm:ss"),
        email: email,
        ip: data.ip,
        city: data?.city || "--",
        stateprov: data?.region || "--",
        country_name: data?.country_name || "--",
        source: JSON.parse(localStorage.getItem("query")),
      };
      values?.ip && postDataToSheets(values);
    } else {
      const { data } = await axios.get("https://hutils.loxal.net/whois");
      window.fbq("track", "Lead");
      window.fbq("track", "SubmitApplication");
      const values = {
        date: moment().utc().format("MM-DD-YYYY, HH:mm:ss"),
        email: email,
        ip: data.ip,
        city: data?.city || "--",
        stateprov: data?.region || "--",
        country_name: data?.country_name || "--",
        source: JSON.parse(localStorage.getItem("query")),
      };
      values?.ip && postDataToSheets(values);
    }
  };

  const sendContactForm = (values, id) => {
    const formData = new FormData();
    for (var name in values) {
      formData.append(name, values[name]);
    }
    const lead = localStorage.getItem("isLead");

    fetch(`${contactFormURL}/${id}/feedback`, {
      method: "POST",
      body: formData,
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === "mail_sent") {
          !lead && getIP(values.your_email);
          localStorage.setItem("isLead", true);
        }
        data?.data?.status
          ? setNotification({
              status: "error",
              message: "Something went wrong. Please try again later.",
            })
          : setNotification(data);
      });
  };

  const sendCV = (data, id) => {
    const formData = new FormData();
    formData.append("file_CV", data);

    setIsloadingCV(true);
    fetch(`${contactFormURL}/${id}/feedback`, {
      method: "POST",
      body: formData,
    })
      .then((data) => data.json())
      .then((data) => {
        setIsloadingCV(false);
        setNotifCV(data);
      });
  };

  const mailChimpSubscribe = (url, email, fname, lname) => {
    jsonp(
      `${url}&EMAIL=${email}&FNAME=${fname}&LNAME=${lname}`,
      { param: "c" },
      (_, data) => {
        if (data.result) {
          setNotification({
            status: data.result !== "success" ? "error" : "success",
            message: data.msg,
          });
        }
      }
    );
  };

  return (
    <FormContext.Provider
      value={{
        mailChimpSubscribe,
        sendContactForm,
        notif,
        setNotification,
        setPdfCV,
        pdfCV,
        sendCV,
        notifCV,
        setNotifCV,
        isLoadigCV,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormState;
