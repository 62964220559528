import React, { useEffect, useState } from "react";
import { Logo } from "../Logo";
import "./index.css";
import Navigation from "../Navigation";
import Menu from "../Menu";

const Header = ({ menu }) => {
  const [white, setWhite] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setWhite(window.pageYOffset > 1));
    }
  }, []);

  return (
    <header className={`topLine row jsb ${white ? "active" : ""}`}>
      <Logo />
      <Navigation menu={menu} />
      <Menu />
    </header>
  );
};

export default Header;
