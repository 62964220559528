import classes from "../../../scss/ContactsList.module.scss";
import { v4 as uuid } from "uuid";
import { useDeferredValue } from "react";

export default function ContactsList({ list, support }) {
  let deferredList = useDeferredValue(list);
  return (
    <ul className={classes.list}>
      {deferredList?.map((item) => (
        <li key={uuid()} className={classes.list__item}>
          <div className={classes.list__icon}>
            <img
              src={item.img_url.full}
              width={item.img_url.fullWidth}
              height={item.img_url.fullHeight}
              alt={item.title.rendered}
            />
          </div>
          <div className={classes.list__content}>
            <h4 className={classes.list__address}>{item.title.rendered}</h4>
            {item.custom_fields.phone && (
              <p className={classes.list__phone}>
                {item.custom_fields.phone[0]}
              </p>
            )}
          </div>
        </li>
      ))}
      <li key={uuid()} className={classes.list__item}>
        <a href={`mailto:${support.href}`}>
          <img src={support.icon} width="20" height="20" alt="mail"></img>
          {support.href}
        </a>
      </li>
    </ul>
  );
}
